<template>
    <section
    class="overflow-auto custom-scroll2 bg-white"
    style="max-height: calc(100vh - 196px);"
    >
        <div class="row mx-0 py-2 mt-3">
            <div class="col-auto px-4" />
            <div class="col-2 text-general f-500">
                Nombre
            </div>
        </div>
        <div
        v-for="(i, index) in lista"
        :key="index"
        class="row mx-0 border-bottom py-3 pr-3"
        >
            <div class="col-auto">
                <el-checkbox
                v-model="i.checked"
                class="check-dark"
                :disabled="i.publicidades.length == 0"
                @change="guardarPublicidadCedis(i)"
                />
            </div>
            <div class="col-2 text-general f-15">
                {{ i.nombre }}
            </div>
            <div class="col">
                <!-- <el-date-picker
                v-model="i.rango_fechas"
                :disabled="!i.anuncio"
                type="datetimerange"
                size="small"
                class="w-100"
                @change="guardarPublicidadCedis(i)"
                /> -->
                <div class="row mx-0 mt-2">
                    <div
                    v-for="data in i.publicidades"
                    :key="data.id"
                    class="card-publicidad mb-2 mr-2 d-flex px-2 py-2"
                    >
                        <div class="col-auto px-0">
                            <div class="row mx-0 justifyt-center cr-pointer" @click="previsualizar(data, i)">
                                <img
                                :src="data.miniatura"
                                width="78"
                                height="78"
                                class="obj-cover br-4 border"
                                />
                            </div>
                            <div class="row mx-0 justify-content-around mt-2">
                                <el-tooltip
                                placement="bottom"
                                content="Editar"
                                effect="light"
                                >
                                    <div
                                    class="btn-action cr-pointer border"
                                    @click="editarImagen(data)"
                                    >
                                        <i class="icon-pencil-outline f-20" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip
                                placement="bottom"
                                content="Eliminar imagen"
                                effect="light"
                                >
                                    <div class="btn-action cr-pointer border">
                                        <i class="icon-delete-outline f-20" @click="eliminarImagen(data.id)" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="col pl-2 pr-0 position-relative">
                            <el-tooltip
                            placement="bottom"
                            content="Vencida"
                            effect="light"
                            >
                                <i
                                v-if="showVencida(data)"
                                class="icon-alert-triangle position-absolute text-warning"
                                style="right:1px;"
                                />
                            </el-tooltip>
                            <template v-if="data.fecha_inicio && data.fecha_fin">
                                <p class="f-14 text-general fecha">
                                    {{ formatearFecha(data.fecha_inicio, 'DD MMM YYYY hh:mm a') }} <br />
                                    {{ formatearFecha(data.fecha_fin, 'DD MMM YYYY hh:mm a') }}
                                </p>
                            </template>
                            <template v-else>
                                <p class="f-14 text-general2 fecha">
                                    Sin fecha establecida
                                </p>
                            </template>
                            <div class="row mx-0 align-items-center mt-2 mb-1">
                                <i class="icon-link text-general f-20 link-icon" />
                                <div class="col px-0 f-14 text-general f-600">
                                    {{ showEnlace(data) }}
                                </div>
                            </div>
                            <p class="text-general f-14 nombre">
                                {{ data.titulo }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-auto px-1">
                <img v-if="i.miniatura" :src="i.miniatura" width="44" height="44" class="obj-cover br-2" @click="previsualizar(i)" />
                <div v-else class="br-2 bg-white d-middle-center border" style="width:44px;height:44px;">
                    <i class="icon-image-outline text-general2 f-35" />
                </div>
            </div> -->
            <div style="width:165px;">
                <div class="row mx-0 align-items-center justify-content-around">
                    <i
                    class="icon-add-image text-general f-22 cr-pointer"
                    @click="anadirImagen(i.id_cedis)"
                    />
                    <router-link
                    :to="{
                        name: 'admin.publicidad.ver',
                        params: { tipo, id_cedis: i.id_cedis }
                    }"
                    >
                        <i class="icon-image-search text-general f-22 cr-pointer" />
                    </router-link>
                    <i
                    class="icon-content-duplicate text-general f-22 cr-pointer"
                    @click="duplicarImagen(i)"
                    />
                    <i class="icon-swap-vertical-bold text-general cr-pointer" @click="modalCambiarPosicionImagen(i)" />
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-prev ref="modalPrev" />
        <!-- <modal-anadir-imagen ref="modalAnadirImagen" /> -->
        <modal-duplicar-imagen
        ref="modalDuplicarImagen"
        @duplicar="listarInfo"
        />
        <modal-editar-imagen ref="modalEditarImagen" @update="listarInfo" />
        <modal-cambiar-posicion-imagen ref="modalCambiarPosicionImagen" @update="listarInfo" />
        <modal-eliminar ref="modalEliminarImagen" titulo="Eliminar Imagen" :cargado-modal="loading" no-aceptar adicional="Eliminar" @eliminar="eliminarPublicidad">
            <div class="row mx-0 justify-center mb-4">
                <div class="col-12 f-15 text-general text-center">
                    ¿Desea eliminar esta imagen?
                </div>
            </div>
        </modal-eliminar>
    </section>
</template>

<script>
import moment from "moment";
import Publicidad from "~/services/publicidad/publicidad_admin";
import { mapGetters } from "vuex";
export default {
    components: {
        modalPrev: () => import("../partials/modalPrevisualizacion"),
        // modalAnadirImagen: () => import('./partials/modalAnadirImagen'),
        modalDuplicarImagen: () => import("../partials/modalDuplicarImagen"),
        modalEditarImagen: () => import("../partials/modalEditarPopUp"),
        modalCambiarPosicionImagen: () => import("../partials/modalCambiarPosicionImagen"),
    },
    props: {
        tipo: {
            type: Number,
            required: true
        }
    },
    data(){
        return {
            lista: [],
            id_anuncio: null,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            cedis: "cedis/cedis"
        })
    },
    mounted(){
        this.listarInfo();
    },
    methods: {
        modalCambiarPosicionImagen(i){
            this.$refs.modalCambiarPosicionImagen.toggle(i.publicidades)
        },
        async listarInfo(){
            try {
                const { data } = await Publicidad.getImagenes(this.tipo);
                this.lista = data.anuncios;
            } catch (e){
                this.error_catch(e);
            }
        },
        eliminarImagen(id){
            this.id_anuncio = id
            this.$refs.modalEliminarImagen.toggle();
        },
        async guardarPublicidadCedis(i){
            try {
                let params = {
                    tipo: this.tipo,
                    id_cedis: i.id_cedis,
                    estado: i.checked
                }
                
                const { data } = await Publicidad.cambiarEstado(params);
                if(data.exito){
                    this.notificacion('', 'Actualizado correctamente', 'success')
                    this.listarInfo()
                }
                
            } catch (e){
                this.error_catch(e);
            }
        },
        previsualizar(info, i){
            let { imagen, titulo, fecha_inicio, fecha_fin, redireccion } = info
            let { nombre: cedis } = i
            let model = {
                imagen,
                titulo,
                fecha_inicio,
                fecha_fin,
                redireccion,
                cedis,
            }
            this.$refs.modalPrev.toggle(model);
        },
        anadirImagen(id_cedis){
            this.$emit("agregar", { id_cedis, tipo: this.tipo });
        },
        duplicarImagen({ id_cedis }){
            let cedisValidos = this.cedis.filter(
                el => el.id != id_cedis && el.id != 0
            );
            cedisValidos.forEach(el => {
                el.checked = false;
            });
            this.$refs.modalDuplicarImagen.toggle({
                cedisValidos,
                id_cedis,
                tipo: this.tipo
            });
        },
        updateView(){
            this.listarInfo();
        },
        editarImagen(data){
            this.$refs.modalEditarImagen.toggle(data);
        },
        showVencida({fecha_inicio, fecha_fin}){
            if(!fecha_inicio && !fecha_fin) return false
            if(moment().isBetween(moment(fecha_inicio), moment(fecha_fin))) return false
            return true
        },
        showEnlace({redireccion}){
            if(redireccion == 0) return 'Sin enlace'
            if(redireccion == 1) return 'Categoría'
            if(redireccion == 2) return 'Promoción'
            if(redireccion == 3) return 'Producto'
        },
        async eliminarPublicidad(){
            try {
                this.loading = true

                const {data} = await Publicidad.eliminarPublicidad(this.id_anuncio)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.id_anuncio = null
                await this.listarInfo()
                this.$refs.modalEliminarImagen.toggle();
            } catch (e){
                this.error_catch(e);
            } finally {
                this.loading = false
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.card-publicidad {
    border: 1px solid #f5f5f5;
    background-color: #f6f9fb;
    border-radius: 4px;
    width: 280px;
}
.fecha{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}

.link-icon{
    transform: rotate(125deg);
}
</style>
